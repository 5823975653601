<template>
    <v-layout wrap>
        <v-flex class="d-flex justify-space-between" xs12>
            <span class="display-1 px-5 pb-5 font-weight-medium">{{
                $route.meta.name
            }}</span>
            <v-btn
                v-if="$auth.check() && hasPermission"
                @click="add"
                color="secondary"
                >Adaugă Legislație</v-btn
            >
        </v-flex>
        <v-flex ml-5 xs12>
            <v-data-table
                :footer-props="{
                    'items-per-page-options': $vuetifyTableItemsPerPage
                }"
                :headers="headers"
                :items="data.items"
                :loading="loading"
                :options.sync="data.pagination"
                :server-items-length="data.total"
                @update:options="getData"
            >
                <template v-slot:item.name="{ item }">
                    <span class="title">{{ item.name }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        class="mr-2"
                        :href="item.src"
                        color="secondary"
                        tile
                        x-small
                        target="_blank"
                        >Vizualizează</v-btn
                    >
                    <v-btn
                        v-if="$auth.check() && hasPermission"
                        class="mr-2"
                        @click="edit(item)"
                        color="success"
                        tile
                        x-small
                        >Editează</v-btn
                    >
                    <v-btn
                        v-if="$auth.check() && hasPermission"
                        @click="destroy(item)"
                        color="error"
                        tile
                        x-small
                        >Șterge</v-btn
                    >
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script>
import hasPermission from "../mixins/hasPermission";

export default {
    name: "Legislation",
    metaInfo: {
        title: "Legislație -"
    },
    mixins: [hasPermission],
    data: () => ({
        data: [],
        loading: false,
        headers: [
            { text: "Nume", align: "left", sortable: false, value: "name" },
            { text: "Acțiuni", align: "end", sortable: false, value: "actions" }
        ]
    }),
    methods: {
        edit(item) {
            this.$router.push({
                name: "edit-legislation",
                query: {
                    id: item.id
                }
            });
        },
        async destroy(item) {
            const res = await this.$confirm({
                message: "Sunteți sigur(ă) că vreți să ștergeți legislația?"
            });
            if (res) {
                try {
                    await this.axios.post(`/legislations/${item.id}`, {
                        _method: "DELETE"
                    });
                    const index = this.data.items.indexOf(item);
                    this.data.items.splice(index, 1);
                } catch (e) {
                    this.loading = false;
                }
            }
        },
        add() {
            this.$router.push({
                name: "add-legislation"
            });
        },
        async getData() {
            this.loading = true;
            const response = await this.axios.get("/legislations", {
                params: this.data.pagination
            });
            this.data = response.data;
            this.loading = false;
        }
    }
};
</script>
